import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const positiveDecimalRegex = /^\d*\.?\d*$/;

interface Token {
  address: string;
  decimals: number;
}

interface SwapInputState {
  inputAmount: string;
  srcToken: Token | null;
  destToken: Token | null;
}

const initialState: SwapInputState = {
  inputAmount: '',
  srcToken: null,
  destToken: null,
};

const swapInputSlice = createSlice({
  name: 'swapInput',
  initialState,
  reducers: {
    setInputAmount: (
      state,
      action: PayloadAction<{ amount: string}>
    ) => {
      if (
        action.payload.amount === '' ||
        positiveDecimalRegex.test(action.payload.amount)
      ) {
        state.inputAmount = action.payload.amount;
      }
    },
    setClear: (state) => {
      state.inputAmount = '';
    },
    setSrcToken: (state, action: PayloadAction<Token>) => {
      state.srcToken = action.payload;
    },
    setDestToken: (state, action: PayloadAction<Token>) => {
      state.destToken = action.payload;
    },
  },
});

export const { setInputAmount, setClear, setSrcToken, setDestToken } =
  swapInputSlice.actions;

export default swapInputSlice.reducer;
