import { http, createConfig } from "@wagmi/core";
import { mainnet, bsc, base, arbitrum } from "wagmi/chains";

export const publicChains = [mainnet, bsc, base, arbitrum] as const;
export const Chain = publicChains.reduce((acc, chain) => {
	acc[chain.id] = chain.name
	return acc;
}, {} as Record<number, string>);

// make use here powerfull rpc
export const publicTransports = {
  [mainnet.id]: http(),
  [arbitrum.id]: http(),
  [bsc.id]: http(),
  [base.id]: http(),
  
 
};
export const IMAGE_ASSET = {
  [mainnet.id]: 'ethereum',
  [arbitrum.id]: 'arbitrum',
  [base.id]: 'base',
  [bsc.id]: 'smartchain',
}

export const publicWagmiConfig = {
  chains: publicChains,
  transports: publicTransports,
  batch: {
    multicall: {
      wait: 64,
    },
  },
} as const satisfies Parameters<typeof createConfig>[0];

export type PublicWagmiConfig = ReturnType<
  typeof createConfig<
    (typeof publicWagmiConfig)["chains"],
    (typeof publicWagmiConfig)["transports"]
  >
>;
